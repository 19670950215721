import React, { useEffect, useState } from 'react';
import '../templateGeneration/generatedEnsemblePanelPreview.scss';
import { GetPreviewUrlFromFrontTemplateToken } from '../../utils/templatePreviewUtility';

interface Props {
    sourceTemplateToken: string;
    width: number;
    panelType: string;
}

const OriginalTemplatePanelPreview = ({ sourceTemplateToken, width, panelType }: Props): JSX.Element => {
    const [originalTemplatePreviewUrl, setOriginalTemplatePreviewUrl] = useState('');

    useEffect(() => {
        const getTemplateUrl = async (): Promise<void> => {
            const templateUrl = await GetPreviewUrlFromFrontTemplateToken(sourceTemplateToken, width, panelType);

            setOriginalTemplatePreviewUrl(templateUrl);
        };

        getTemplateUrl();
    }, [sourceTemplateToken, width, panelType]);

    return (
        <div className="ensemble-preview">
            <div className="ensemble-panel-tile">
                <a href={originalTemplatePreviewUrl} target="_blank" rel="noopener noreferrer">
                    <img src={originalTemplatePreviewUrl} alt="preview" width={width} />
                </a>
            </div>
        </div>
    );
};

export default OriginalTemplatePanelPreview;
