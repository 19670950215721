import React from 'react';
import { GetTemplateDeliveryUrl, getSceneUrl } from '../../utils/templatePreviewUtility';

interface Props {
    templateToken: string;
    designPhysicalSpecId?: string;
    backgroundColor?: string;
    previewWidth?: number;
    // useFakeFamily?: boolean;
    tenant: TemplateCatalog.Tenant;
}

export function GetTemplatePreviewUrl({
    templateToken, designPhysicalSpecId, backgroundColor, previewWidth, tenant,
}: Props): string {
    let width = 500;

    if (previewWidth) {
        width = previewWidth;
    }

    const encodedDeliveryUri = encodeURIComponent(encodeURIComponent(GetTemplateDeliveryUrl(tenant, templateToken, backgroundColor)));

    let renderingUri = `https://rendering.mcp.cimpress.com/v2/vp/preview?format=png&instructions_uri=https%3A%2F%2Fuds.documents.cimpress.io%2Fv2%2Ftransient%2Fvp%3FdocumentUri%3D${encodedDeliveryUri}%26type%3Dpreview&quality=95&width=${width}`;

    if (designPhysicalSpecId) {
        const encodedSceneUri = encodeURIComponent(getSceneUrl(designPhysicalSpecId, width));

        renderingUri = `${renderingUri}&scene=${encodedSceneUri}`;
    }

    return renderingUri;
}

const TemplatePreview = ({
    templateToken,
    designPhysicalSpecId,
    backgroundColor,
    previewWidth,
    tenant,
// eslint-disable-next-line arrow-body-style
}: Props): JSX.Element => {
    // const { useFakeFamily } = useContext(PreferencesConfigurationContext);

    return (
        <img
            src={GetTemplatePreviewUrl({
                templateToken, designPhysicalSpecId, backgroundColor, previewWidth, tenant,
            })}
            className="template-preview"
            alt="Template preview"
        />
    );
};

export default TemplatePreview;
