import { Card, Button, shapes } from '@cimpress/react-components';
import React, { useState } from 'react';
import TemplatePreview from './templatePreview';
import './panelDetailsCard.scss';
import downloadHighResolutionPDFByTemplateToken from '../../services/pdfDownloadService';
import DesignPhysicalSpecDetailsSection from './designPhysicalSpecDetailsSection';

interface Props {
    panel: TemplateCatalog.Panel;
    tenant: TemplateCatalog.Tenant;
}

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
const PanelDetailsCard = ({ panel, tenant }: Props): JSX.Element => {
    const { Spinner } = shapes;
    // the state used to indicate if the pdf is gnenerating
    const [isProcessingPdfDownload, setIsProcessingPdfDownload] = useState<boolean>(false);
    // the link to the hi-res pdf
    const [hiResPdfLink, setHiResPdfLink] = useState<string>();

    /**
     * Used to download high-res pdf of a template
     * @param templateToken: The templateToken of the template we want to download
     */
    async function fetchPdfByTemplateToken(templateToken: string): Promise<void> {
        setIsProcessingPdfDownload(true);

        const result = await downloadHighResolutionPDFByTemplateToken(templateToken, tenant);

        if (typeof result === 'string') {
            setHiResPdfLink(result);
        } else {
            // if failed, restore generate pdf button so the user can retry
            setIsProcessingPdfDownload(false);
        }
    }

    return (
        <Card className="panel-details-card-style">
            <div className="panel-details-card-preview">
                <TemplatePreview
                    templateToken={panel.templateToken}
                    designPhysicalSpecId={panel.designPhysicalSpecId}
                    backgroundColor={panel.dpsSubstrateColor}
                    tenant={tenant}
                />
            </div>
            <div className="panel-detail">
                <div className="panel-details-item">
                    <div className="panel-details-card-text">
                        <p>
                            Panel Type: {panel.ensemblePanelType}
                        </p>
                    </div>
                </div>
                <div className="panel-details-item">
                    <div className="panel-details-card-text">
                        <p>
                            Template ID: {panel.templateId}
                        </p>
                    </div>
                </div>
                <div className="panel-details-item">
                    <div className="panel-details-card-text">
                        <p>
                            Template Token: {panel.templateToken}
                        </p>
                    </div>
                </div>
                <DesignPhysicalSpecDetailsSection
                    displayName={panel.dpsDisplayName}
                    displayColor={panel.dpsDisplayColor}
                    dpsId={panel.designPhysicalSpecId}
                    showFrontIndicatorText={false}
                />
                <div className="panel-details-item">
                    <div className="panel-details-card-text">
                        <div>
                            {!hiResPdfLink
                            && ((isProcessingPdfDownload)
                                ? <Spinner size="medium" />
                                : <Button
                                    variant="default"
                                    data-testid="download-button"
                                    onClick={(): void => { fetchPdfByTemplateToken(panel.templateToken); }}
                                > Generate PDF</Button>)}
                            {(hiResPdfLink)
                            && <Button href={hiResPdfLink} target="_blank" variant="default">
                                Open PDF
                            </Button>}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};
/* eslint-enable react/jsx-one-expression-per-line */
/* eslint-enable no-nested-ternary */
/* eslint-enable max-len */

export default PanelDetailsCard;
