import { Card } from '@cimpress/react-components';
import { Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import TemplatePreview from './templatePreview';
import './ensembleLineEnsemblePreviewCard.scss';

interface Props {
    ensemble: TemplateCatalog.Ensemble;
    minified: boolean;
    isProminentEnsemble: boolean;
}

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-boolean-value */
const EnsembleLineEnsemblePreviewCard = ({
    ensemble, minified, isProminentEnsemble,
}: Props): JSX.Element => (
    <Link className="no-link-text" to={{ pathname: `/ensembleDetails/${ensemble.ensembleId}`, state: { ensemble } }}>
        <Card key={ensemble.ensembleId} className="ensemble-line-padded" header={minified ? '' : `Ensemble ID: ${ensemble.ensembleId}`} isMinimal={true}>
            {isProminentEnsemble
            && <p className="ensemble-prominent" data-testid={`prominent-${ensemble.ensembleId}`}>Prominent Color Variant</p>}
            {ensemble.isDeleted
            && <p className="ensemble-deleted" data-testid={`deleted-${ensemble.ensembleId}`}>Deleted</p>}
            {minified
                ? ensemble.templates.filter((panel) => panel.ensemblePanelType === 'front').map((frontPanel) => (
                    <>
                        <TemplatePreview
                            templateToken={frontPanel.templateToken}
                            designPhysicalSpecId={frontPanel.designPhysicalSpecId}
                            backgroundColor={frontPanel.dpsSubstrateColor}
                            tenant={ensemble.tenant}
                            key={frontPanel.templateToken}
                        />
                        <p className="no-link-text text-top-padding">{frontPanel.templateToken}</p>
                    </>
                ))
                : ensemble.templates.map((panel) => (
                    <Grid container item xs="auto" key={panel.templateToken} spacing={2} direction="row">
                        <Grid item xs={6} data-testid={`color-${panel.templateId}-${panel.dpsSubstrateColor}`}>
                            <TemplatePreview
                                templateToken={panel.templateToken}
                                designPhysicalSpecId={panel.designPhysicalSpecId}
                                backgroundColor={panel.dpsSubstrateColor}
                                tenant={ensemble.tenant}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p className="no-link-text" data-testid={`panel-${panel.templateToken}`}>Panel Type: {panel.ensemblePanelType}</p>
                            <p className="no-link-text">Template Token: {panel.templateToken}</p>
                        </Grid>
                    </Grid>
                ))}
        </Card>
    </Link>
);
/* eslint-enable react/jsx-one-expression-per-line */
/* eslint-enable react/jsx-boolean-value */

export default EnsembleLineEnsemblePreviewCard;
