import ObjectsToCsv from 'objects-to-csv';
import React from 'react';
import { Button } from '@cimpress/react-components';
import { isVistaprintOrCimpressUser } from '../../auth';
import { GetTemplatePreviewUrl } from '../browseEnsembles/templatePreview';
// import PreferencesConfigurationContext from '../preferences/preferencesContext';

interface Props {
    templates: PanelWithEnsembleInfo.PanelWithEnsembleInfo[];
    previewWidth: number;
}

class Row {
    'Preview Image': string;

    'Template Token ID': string;

    'Combo ID'?: string;
}

const ExportBatchSearchResults = ({ templates, previewWidth }: Props): JSX.Element => {
    // const { useFakeFamily } = useContext(PreferencesConfigurationContext);

    function downloadFile(input: string, outputFileName: string): void {
        const element = document.createElement('a');
        const file = new Blob([input], { type: 'text/plain' });

        element.href = URL.createObjectURL(file);
        element.download = outputFileName;

        // for unit testing
        element.id = 'export-batch-results-link';
        element.textContent = input;
        element.style.display = 'none';

        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    // https://support.google.com/docs/answer/3093333
    function getTemplatePreviewUrlForCSV(
        templateToken: string,
        designPhysicalSpecId: string,
        tenant: TemplateCatalog.Tenant,
        substrateColor?: string,
    ): string {
        return `${GetTemplatePreviewUrl({
            templateToken,
            designPhysicalSpecId,
            backgroundColor: substrateColor,
            previewWidth,
            tenant,
        })}`;
    }

    async function exportBatchResults(): Promise<void> {
        if (!templates || templates.length === 0) {
            return;
        }

        const isVpOrCmpUser: boolean = isVistaprintOrCimpressUser();
        const rows = [] as Row[];

        for (let i = 0; i < templates.length; i += 1) {
            const { templateToken } = templates[i].template;
            const dpsId = templates[i].template.designPhysicalSpecId;
            const substrateColor = templates[i].template.dpsSubstrateColor;
            const { tenant } = templates[i].ensembles[0];

            if (templateToken) {
                let row = new Row();

                if (isVpOrCmpUser) {
                    row = {
                        'Preview Image': getTemplatePreviewUrlForCSV(templateToken, dpsId, tenant, substrateColor),
                        'Template Token ID': templateToken,
                        'Combo ID': templates[i].comboId ?? '',
                    };
                } else {
                    row = {
                        'Preview Image': getTemplatePreviewUrlForCSV(templateToken, dpsId, tenant, substrateColor),
                        'Template Token ID': templateToken,
                    };
                }
                rows.push(row);
            }
        }

        const csv = new ObjectsToCsv(rows);

        downloadFile(await csv.toString(), 'results.csv');
    }

    return (
        <Button
            className="export-batch-button"
            variant="default"
            data-testid="export-batch-button-test-id"
            onClick={(): void => { exportBatchResults(); }}
        >
            Export to CSV
        </Button>
    );
};

export default ExportBatchSearchResults;
