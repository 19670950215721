import Card from '@cimpress/react-components/lib/Card';
import Grid, { GridSize } from '@material-ui/core/Grid';
import React from 'react';
import TemplatePreview from '../browseEnsembles/templatePreview';

/* eslint-disable react/jsx-one-expression-per-line */

interface Props {
    ensemble: TemplateCatalog.Ensemble;
    isProminent: boolean;
    className?: string;
}

const EnsemblePreview = ({ ensemble, isProminent, className }: Props): JSX.Element => {
    const panelOrder = ['front', 'insideFront', 'inside', 'insideBack', 'back'];

    const getSortedTemplates = (): TemplateCatalog.Panel[] | undefined => {
        if (!ensemble) {
            return undefined;
        }

        const templates: TemplateCatalog.Panel[] = [];

        panelOrder.forEach((panelName) => {
            const template = ensemble.templates.find((t) => t.ensemblePanelType === panelName);

            if (template) {
                templates.push(template);
            }
        });

        return templates;
    };

    const getPreviewWidth = (): GridSize => {
        const numPanels = ensemble?.templates.length ?? 1;

        return (12 / numPanels) as GridSize;
    };

    return (
        <Grid item className={className}>
            <Card>
                {isProminent && <p className="prominent-label">Prominent Variant</p>}
                <Grid item container direction="row" justify="space-evenly" spacing={1}>
                    {getSortedTemplates()?.map((t) => (
                        <Grid item xs={getPreviewWidth()} key={t.templateId}>
                            <TemplatePreview
                                templateToken={t.templateToken}
                                designPhysicalSpecId={t.designPhysicalSpecId}
                                previewWidth={200}
                                tenant={ensemble.tenant}
                            />
                            <p className="panel-type">{t.ensemblePanelType}</p>
                        </Grid>
                    )
                    )}
                </Grid>
            </Card>
        </Grid>
    );
};

export default EnsemblePreview;
