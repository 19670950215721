/* eslint-disable react/jsx-one-expression-per-line */
import { Label } from '@cimpress/react-components';
import { Grid } from '@material-ui/core';
import React from 'react';
import TemplatePreview from '../browseEnsembles/templatePreview';

export type ReplacementStatus = 'pending' | 'success' | 'failure' | 'already replaced';
type LabelStatus = 'danger' | 'default' | 'info' | 'primary' | 'success' | 'warning';

// The replacement feature is only used by Vistaprint so we can hardcode these values
const tenant: TemplateCatalog.Tenant = {
    accountId: 'ozoDdrmewShEcbUDWX8J3V',
    contentAreaId: 'IoBX4KKMc0uyUyA0tFQDg',
};

interface Props {
    oldTemplateToken: string;
    newTemplateToken: string;
    status: ReplacementStatus;
}

const statusToTextMap: Map<ReplacementStatus, [string, LabelStatus]> = new Map(
    [
        ['pending', ['pending review', 'warning']],
        ['success', ['replacement complete', 'success']],
        ['failure', ['error', 'danger']],
        ['already replaced', ['already replaced', 'info']],
    ],
);

const getStatusText = (replacementStatus: ReplacementStatus): string => {
    const mapEntry = statusToTextMap.get(replacementStatus);

    return mapEntry ? mapEntry[0] : '';
};

const getLabelStatus = (replacementStatus: ReplacementStatus): LabelStatus => {
    const mapEntry = statusToTextMap.get(replacementStatus);

    return mapEntry ? mapEntry[1] : 'info';
};

const AccordianTitle = ({
    oldTemplateToken,
    newTemplateToken,
    status,
}: Props): JSX.Element => (
    <Grid container className="header-content" direction="row" justify="space-evenly">
        <Grid item className="preview-label" xs={2}>
            Original:
        </Grid>
        <Grid item xs={3}>
            <TemplatePreview
                templateToken={oldTemplateToken}
                previewWidth={200}
                tenant={tenant}
            />
        </Grid>
        <Grid item className="preview-label" xs={2}>
            Replacement:
        </Grid>
        <Grid item xs={3}>
            <TemplatePreview
                templateToken={newTemplateToken}
                previewWidth={200}
                tenant={tenant}
            />
        </Grid>
        <Grid item className="status-label" xs={2}>
            <Label text={getStatusText(status)} status={getLabelStatus(status)} />
        </Grid>
    </Grid>
);

export default AccordianTitle;
