import React, { useState } from 'react';
import { TextField } from '@cimpress/react-components';
import { isVistaprintOrCimpressUser } from '../../auth';

interface Props {
    onSearchSubmit: (searchInput: string) => void;
}

const SearchBox = ({ onSearchSubmit }: Props): JSX.Element => {
    const [searchInput, setSearchInput] = useState('');
    const searchByCombo = isVistaprintOrCimpressUser() ? ', combo ID,' : '';
    const searchLabel = `Search by template token${searchByCombo} or design concept ID`;

    const searhSubmitted = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        onSearchSubmit(searchInput);
    };

    return (
        <form onSubmit={searhSubmitted}>
            <TextField
                name="search"
                label={searchLabel}
                value={searchInput || ''}
                rightAddon={(
                    <button className="btn btn-default" type="submit" data-testid="search-submit" aria-label="search">
                        <i className="fa fa-search button-icon" />
                    </button>
                )}
                data-testid="search-input"
                onChange={(e): void => setSearchInput(e.target.value)}
            />
        </form>
    );
};

export default SearchBox;
